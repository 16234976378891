import React from "react";
import axios from "axios";
import "./App.css";

const url =
  "https://firebasestorage.googleapis.com/v0/b/creepies-json.appspot.com/o/whitelist%2010.json?alt=media&token=3a6041f7-14fc-4c17-b232-3267ad9069cf";

function App() {
  const [accountNo, setAccountNo] = React.useState("");
  const [validity, setValidty] = React.useState("");

  const verify = () => {
    if (accountNo && accountNo.length > 0) {
      axios.get(url).then((response) => {
        const arr = response.data;
        if (arr.filter((e) => e === accountNo).length === 1) {
          setValidty("true");
        } else {
          setValidty("false");
        }
      });
    }
  };

  const onChange = (e) => {
    console.log(e.target.value);
    setAccountNo(e.target.value);
  };
  const onBlur = (e) => {
    console.log(e.target.value);
    setAccountNo(e.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://creepies.io/images/eye.gif"
          alt="creepy eye"
          width="300"
        />
        <h2 className="mb-10">Check if your metamask address is whitelisted</h2>
        {validity === "false" && (
          <div
            className="px-4 py-3 text-teal-900 bg-red-700 border-t-4 border-teal-500 rounded-b shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="w-6 h-6 mr-4 text-yellow-400 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold text-red-300">
                  Your wallet is not whitelisted
                </p>
                <p className="text-sm">
                  Make sure you have entered the correct address.
                </p>
              </div>
            </div>
          </div>
        )}
        {validity === "true" && (
          <div
            className="px-4 py-3 text-teal-900 bg-green-600 border-t-4 border-teal-500 rounded-b shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1"></div>
              <div>
                <p className="font-bold text-white">
                  Your wallet is whitelisted!
                </p>
              </div>
            </div>
          </div>
        )}

        <form className="w-full max-w-md">
          <div className="flex items-center py-2 border-b border-teal-500">
            <input
              className="w-full px-2 py-1 mr-3 text-base leading-tight text-yellow-400 bg-transparent border-none appearance-none focus:outline-none"
              type="text"
              aria-label="account number"
              value={accountNo}
              onChange={onChange}
              onBlur={onBlur}
            />
            <button
              className="flex-shrink-0 px-2 py-1 text-sm text-teal-500 border-4 border-transparent rounded hover:text-teal-800"
              type="button"
              onClick={() => {
                setAccountNo("");
                setValidty("");
              }}
            >
              Clear
            </button>
            <button
              onClick={verify}
              className="px-4 py-2 font-bold text-black bg-yellow-400 rounded-full hover:bg-yellow-300"
              type="button"
            >
              Verify
            </button>
          </div>
        </form>
        <a
          className="text-yellow-400"
          href="https://creepies.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          © creepies.io
        </a>
      </header>
    </div>
  );
}

export default App;
